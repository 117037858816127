.Coluna-padrao-sec{
    border: 1px solid #d6d3d3;
    border-collapse: collapse;
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px;
    min-width: 110px;
    max-width: 110px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  }
  