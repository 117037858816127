.backgruound-style-incluir{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;  
  justify-content: center; 
  background-color: rgba(0,0,0,0.5);

}

.modal-alert-incluir {
  z-index: 100;
  background: rgb(255, 255, 255);
  position: relative;
  margin: 30px  auto;
  max-width: 700px;
  max-height: 555px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}



@media only screen and (max-width: 530px) {

  .backgruound-style-incluir{
    padding-left: 10px;
    padding-right: 10px;
  }

  .modal-alert-incluir {
    
  }
}