.backgruound-style{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;  
  justify-content: center; 
  background-color: rgba(0,0,0,0.5);
  padding-top: 60px;

}

.modal-alert {
  z-index: 100;
  background: rgb(255, 255, 255);
  position: relative;
  margin: 80px  auto;
  max-width: 515px;
  border-radius: 8px;
  height: auto;
}


@media only screen and (max-width: 530px) {

  .backgruound-style{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 180px;
  }
}