.img-dash2{
  width: 90%;
  height: 54px;
}


.sidebar {
  width: 220px;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background: #13293D;
  z-index: 100;
  transition: width 300ms;
  align-items: center;
}

.sidebar .lab-icone {
  font-weight: bold;
  cursor: pointer;
  font-size: 13px;
  margin-left: -5px;
  margin-right: 15px;
}

.sidebar .lab-icone2 {
  font-weight: bold;
  cursor: pointer;
  font-size: 13px;
  margin-left: -5px;
  margin-right: 18px;
}

.sidebar-brand {
  height: 50px;
  padding: 0.5rem 0rem 0rem 0.5rem;
  color: #fff;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 15px;
}

.sidebar-brand span {
  display: inline-block;
  font-size: 16px;
}

.sidebar-menu{
  height: 85%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 5px;
}

.sidebar-menu::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
  border-top-left-radius: 10px;
  border-radius: 20px;
 
}

.sidebar-menu::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);   
  border-radius: 20px;  

}

.sidebar-menu::-webkit-scrollbar-thumb {
  background-color: rgb(224, 219, 219);    
  border-radius: 20px;    
  border: 3px solid rgb(224, 219, 219);  
 
}

#nav-toggle:checked + .sidebar .sidebar-menu .side  {
  margin-left: 10px;
}


.sidebar-menu .side {
  height: 30px;
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-left: 5px;
}

.sidebar-menu a {
  color: #ffffff;
  list-style: none;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  text-decoration: none;
  transition: var(--tran-03);
  margin-left: 2px;
  margin-right: 2px;
  cursor: pointer;

}


.sidebar-menu a:hover {
  background: #ffffff;
  color: #13293D;
  

}

.sidebar-menu a span:first-child {
  font-size: 18px;
  padding-right: 1rem;
  padding-left: 1rem;
}

#inline {
  display: inline;
}

.Legenda-side {
  font-size: 12px;
  background-color: #13293D;
  color:#ffffff;
  padding: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  margin-left: 112%;
  display: none;
  border: 1px solid #5c5c5c;
 
}

.Legenda-side:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  left: -4px;
  margin-top: 2px;
  background: #13293D;
  color: #eee6e6;
  -webkit-transform: rotate(45deg);

}

/* Dropdown */

.nav__dropdown-content a {
  padding: 16px;
  overflow: hidden;
  max-height: 13px;
  transition: 0.4s ease-in-out;
  font-weight: bold;
  font-size: 13px;
}

.nav__dropdown-content {
  display: grid;
  row-gap: 0.1rem;
  padding: 0.2rem 1.5rem 0.2rem 1rem;
  cursor: pointer;
}

.side-dropdown:checked + .nav__dropdown-content {
  display: block;
}

.sidebar i {
  color: #b8c7ce;
  margin-left: 70px;
}

.sidebar-menu a:hover i {
  color: #d1d1d1;
  -moz-transform: scale(2.2);
  -webkit-transform: scale(5.5);
  transform: scale(1.3);
  
}

.Legenda-Dropdown {
  font-size: 20px;
  background: #13293D;
  padding: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  margin-left: 80%;
  margin-top: 50px;
  display: none;
}


.Legenda-Dropdown a:hover {
  background: #d1d1d1;
  color: #ffffff;
  opacity: 0.8;

}


.Legenda-Dropdown h1{
  font-size: 12px;

  margin-top: -0px;
  margin-left: -6px;
   color: #ffffff;
}

.Legenda-Dropdown h1:hover{
  color: #ffffff;

}

.Controle{
  margin-left: 10px;
}

.icone-navDropdown{
  font-size: 16px;
}

.icone-navDropdown2{
  font-size: 16px;
  margin-left: 30px;
}
