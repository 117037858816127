.container-principal {
  background-color: #fff;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.container-cad-prod1 {
  display: grid;
  width: 100%;
  grid-template-columns: 4fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod2 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod3 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr;
  grid-template-areas: "content1";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod4 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr;
  grid-template-areas: "content1 content2";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod5 {
  display: grid;
  width: 100%;
  grid-template-columns: 4fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod6 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod7 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 3fr 3fr 1.5fr 1.5fr 1.5fr;
  grid-template-areas: "content1 content2 content3 content4 content5 content6";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod8 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr;
  grid-template-areas: "content1 content2";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod9 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr 1fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod10 {
  display: grid;
  width: 100%;
  grid-template-columns: 3fr 0.5fr 0.5fr 2fr 0.5fr 0.5fr 2fr;
  grid-template-areas: "content1 content2 content3 content4 content5 content6 content7";
  grid-gap: 0.5rem;
  margin-bottom: 4px;
}

.container-cad-prod11 {
  display: grid;
  width: 100%;
  grid-template-columns: 3fr 2fr 3fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod12 {
  display: grid;
  width: 100%;
  grid-template-columns: 4fr 2fr 3fr 2fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}


.container-cad-prod13 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 0.5fr 0.8fr 2fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod14 {
  display: grid;
  width: 100%;
  grid-template-columns: 1.2fr 0.9fr 0.7fr 1.8fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod15 {
  display: grid;
  width: 100%;
  grid-template-columns: 4fr 2fr 1fr 2fr 1fr;
  grid-template-areas: "content1 content2 content3 content4 content5";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod16 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "content1 content2 content3 content4 content5";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod17 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod18 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod19 {
  display: grid;
  width: 100%;
  grid-template-columns: 1.5fr 2fr 1.5fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}


.container-cad-prod20 {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas: "content1 content2 content3 content4 content5";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.container-cad-prod21 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}


.container-cad-prod22 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1.5fr 1.2fr 1.2fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}

.input-flex-ativos {
  display: flex;
}

.details-garantia-ativos {
  margin-top: 6px;
  margin-left: 8px;
  margin-right: 8px;
}


#content1 {
  grid-area: content1;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
}

#content2 {
  grid-area: content2;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
}

#content3 {
  grid-area: content3;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
}

#content4 {
  grid-area: content4;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
}

#content5 {
  grid-area: content5;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
}

#content6 {
  grid-area: content6;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
}

#content7 {
  grid-area: content7;
  border-radius: var(--main-radius);
  padding-top: var(--main-padding);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  color: #455560;
}
/*---------------------------------DESCRIÇÃO DE CADA BLOCO COM NUMERAÇÃO-------------------------------------------*/
.title-Page .title-text h2 {
  color: #455560;
  font-size: 18px;
  margin-bottom: 13px;
  font-weight: normal;
 margin-top: 3px;
}

.title-Page hr {
  margin-top: 10px;

}

.title-Page {
  display: flex;

}

.title-text {
  display: flex;
  width: 100%;

}

.size-x {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: #455560;
  margin-right: 10px;
}

.circle {
  background: #455560;
  color: #fff;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
}

.title-Page {
  border-bottom: 1px solid #12344D;
  height: 35px;
  margin-bottom: 10px;
 
}

/*----------------------------------------------------------------------------*/

.modal-titulo {
  font-size: 16px;
  margin-top: 2px;
  margin-left: 10px;
  color: #fff;
}

 .container-cad-dash1 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
}

.container-cad-dash1-act {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  grid-template-areas: "content1 content2 content3 content4";
  grid-gap: 0.5rem;
  margin-bottom: 5px;
}


.container-cad-dash2 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 2fr;
  grid-template-areas: "content1 content2";
  grid-gap: 0.5rem;
  margin-bottom: 9px;
}


.container-cad-dash3 {
  display: grid;
  width: 100%;
  grid-template-columns: 2fr;
  grid-template-areas: "content1";
  grid-gap: 0.5rem;

}

@media only screen and (max-width: 1020px) {

  .container-cad-dash1 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
      "content4";
    grid-gap: 0rem;
    margin-bottom: 10px;
  }
  
    .container-cad-dash2 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2";
    grid-gap: 1rem;
    margin-bottom: 10px;
    margin-top: 5px;
  }

  .container-cad-dash3 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2";
    grid-gap: 1rem;
    margin-bottom: 10px;
  }

  .class-checkbox-user {
    padding: 0px;
    margin-top: 10px;
    margin-left: 0px;  
  }

  .container-cad-prod1 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3";
    grid-gap: 10px;
    margin-bottom: 10px;
  }

  .container-cad-prod2 {
    grid-template-columns: 2fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod4 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod5 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod6 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
      "content4";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod7 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
      "content4"
      "content5"
      "content6";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod8 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod9 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod10 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
      "content4"
      "content5"
      "content6"
      "content7";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod11 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod12 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
      "content4";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod13 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
      "content4";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod14 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
      "content4";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod15 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
      "content4"
      "content5";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod17{
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod18{
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod19{
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3";
      grid-gap: 10px;
      margin-bottom: 10px;
  }
  .container-cad-prod20 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
      "content4";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod21 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2";
  }

  .container-cad-dash1-act {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
	  "content4";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

  .container-cad-prod22 {
    grid-template-columns: 1fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
	  "content4";
      grid-gap: 10px;
      margin-bottom: 10px;
  }

}

@media only screen and (max-width: 600px) {

  .title-Page .title-text h2 {  
    font-size: 16px;
  }

  .title-Page {
    border-bottom: 1px solid #12344D;
    height: 40px;
    margin-bottom: 10px; 
  }
}



.class-checkbox-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  padding-top: 25px;
  margin-left: 30px;
}

.class-checkbox-user label {
  margin-left: 30px;
}


.container-modal-alert{
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  padding: 40px;
  display: block;
  min-height: 250px;
}

.div-alert{
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto;

}

.ControleIconAlert{
  font-size: 100px;
  color: #a5dc86;
   margin-bottom: 20px;
}


.ControleIconAlertcancel{
  font-size: 100px;
  color: #f27474;
   margin-bottom: 20px;
}

.info-alert{
  color: #545454;
  font-size: 18px;
  margin-bottom: 10px;
}

.btn-alert-div{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

}

.btn-alert{
  margin-top: 20px;
  background-color: rgb(34, 45, 50);
  padding: 10px;
  border-radius: 5px;
  width: 50px;
  cursor: pointer;
  margin: 0 auto;
}

.btn-alert-esc{

  background-color: rgb(34, 45, 50);
  padding: 10px;
  border-radius: 5px;
  width: 50px;
  cursor: pointer;
  margin-right: 10px;
}

.btn-alert-dir{

  background-color: rgb(197, 7, 7);
  padding: 10px;
  border-radius: 5px;
  width: 50px;
  cursor: pointer;
  margin-left: 10px;
}

.btn-alert-dir:hover{
  opacity: 0.8;
}

.btn-alert-esc:hover{
  opacity: 0.8;
}

.btn-alert:hover{
  opacity: 0.8;
}

.btn-alert-lab{
  color: #fff;
}

.div-info-alert{
  margin-bottom: 20px;
}

.div-info-alert-atencao{
  margin-bottom: 20px;
  font-weight: bold;
 
}

.info-alert-atencao{
  font-size: 25px;
  color: #545454;
}

.DualListBox{
  margin-top: 20px;
  margin-bottom: 20px;
}