
header {
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 2px 2px 5px rgb(0, 0, 0, 0.2);
    position: fixed;
    left: 220px;
    width: calc(100% - 220px);
    top:0;
    z-index: 100;
    transition: left 300ms;  
  }
  
  header h2 {
    color:  #403c3f;
    font-size: 18px;
    margin-top: 3px;
  }

  @media only screen and (max-width: 768px){
 
    header h2 {
      color:  #403c3f;
      font-size: 14px;   
    }
  }


  header  h4{
    color: #403c3f;
    font-size: 14px;
  }

  .search-wrapper {
    border: 1px solid #ccc;
    border-radius: 30px;
    height: 50px;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    
  }
  

  .search-wrapper span {
    display: inline-block;
    padding: 0rem 1rem;
    font-size: 1.5rem;
    
  }
  
  .search-wrapper input {
    height: 100%;
    padding: .5rem;
    border:none;
    outline: none;
  }
  
  .user-wrapper {
    display: flex;
    align-items: center;
  }
  
  .user-wrapper img{
    border-radius: 50%;
    margin-right: 1rem;
  }
  
  .user-wrapper small {
    display: inline-block;
    color: var(--text-grey);
    cursor: pointer;
  }

  .user-wrapper small:hover{
    color:#000000;
    font-weight: bold;
  }

  .ico{
    color: #403c3f;
    padding: 20%;
    font-size: 22px;
    margin-left: 15px;
  }
  
  .ico:hover {
    cursor: pointer;
    background: #ebeff3;
    border-radius: 6px;
  }

  